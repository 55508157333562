"use client";

import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import { StyledWidgetHero } from "./widgetHero.styles";
import { IPlatform, IPlugin } from "../../types";
import { WidgetNavbar } from "../widgetNavbar/widgetNavbar.comp";
import { CustomLink } from "../link/link.comp";
import { SocialProof } from "../socialProof/socialProof.comp";
import { Popup } from "../popup/popup.comp";
import { trackEvent } from "@/lib/track";
import { ParallaxEditor } from "../parallax-editor/parallax-editor.comp";
import { WidgetsSocialProof } from "../widgetsSocialProof/widgetsSocialProof.comp";
import { Header } from "../header/header.comp";
import { CommonNinjaWidgetRenderer } from "../CommonNinjaWidgetRenderer/commonNinjaWidgetRenderer.comp";

interface IWidgetHeroProps {
  widget: IPlugin;
  platform?: IPlatform;
}

export const WidgetHero = ({ widget, platform }: IWidgetHeroProps) => {
  const [formPopupOpen, setFormPopupOpen] = useState(false);

  useEffect(() => {
    const eventBody: { [key: string]: string } = {
      serviceName: widget.serviceName,
    };

    if (platform?.slug) {
      eventBody.platform = platform.slug;
    }

    trackEvent(`Enter App Page`, eventBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledWidgetHero>
        <Header fixedPosition={true} style={{ background: "transparent" }} />
        <div style={{ padding: "0 40px" }}>
          <WidgetNavbar
            setFormPopupOpen={setFormPopupOpen}
            widget={widget}
            platform={platform}
          />
        </div>
        <div className="hero-wrapper">
          <h1
            className={`${
              widget.status === "coming-soon" ? "coming-soon" : ""
            } `}
          >
            {widget.name}
            {platform?.title ? ` for ${platform.title}` : ""}
          </h1>
          <h2>{widget.teaser}</h2>
          <div className="button-wrapper">
            {widget.status !== "coming-soon" ? (
              <CustomLink
                variant="gradient"
                external
                title={`${widget.buttonText} ${
                  platform ? `for ${platform.title}` : ""
                }`}
                className="try-button"
                id="main-create-widget-cta"
                href={`/${widget.slug}/editor`}
                onClick={() => {
                  // trackEvent(`Landing page ${widget.name}${platform ? ' ' + platform.title : ''}`, { serviceName: widget.serviceName });
                }}
              >
                {widget.buttonText}
              </CustomLink>
            ) : (
              <CustomLink
                variant="gradient"
                external
                title={`Get Notified when ${widget.name} get released.`}
                className="try-button"
                href={`#`}
                onClick={(e) => {
                  e.preventDefault();
                  // trackEvent(`Coming Soon Landing page ${widget.name}`);
                  setFormPopupOpen(true);
                }}
              >
                Get Notified
              </CustomLink>
            )}
            <SocialProof />
          </div>
          <div className="hero-img-wrapper">
            {platform?.icon && (
              <div className="platform-img-wrapper">
                <Image src={platform.icon} alt={platform.title} fill />
              </div>
            )}
            <ParallaxEditor
              hideLayoutsImg={true}
              widgetImgSrc={widget.hero}
              contentImgSrc={"/assets/widget-hero-left.svg"}
              widgetImgStyle={{
                width: "83%",
                top: "13%",
                left: "10%",
              }}
              contentImgStyle={{
                top: "38%",
              }}
              skinsImgStyle={{
                top: "17%",
                left: "78%",
              }}
            />
          </div>
        </div>
        <WidgetsSocialProof />
        <CommonNinjaWidgetRenderer
          widgetId={"87cf32fd-d8c2-4a3b-8cfc-0f419c000fbf"}
        />
      </StyledWidgetHero>
      {widget.status === "coming-soon" && (
        <Popup onClose={() => setFormPopupOpen(false)} show={formPopupOpen}>
          <CommonNinjaWidgetRenderer
            widgetId="c612c6ae-f037-4b80-93e1-0636940f6f5b"
            widgetProps={encodeURIComponent(
              JSON.stringify({
                "9120c715-4583-429b-8434-38c634726518": widget.name,
              })
            )}
          />
        </Popup>
      )}
    </>
  );
};
